
import { defineComponent, reactive, toRefs, shallowRef, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getAdd } from './api'
import { required, carNo } from '@/utils/rules'
export default defineComponent({
  props: {
    modelValue: Boolean,
    title: String,
    rowData: Object
  },
  meits: ['update:modelValue'],
  setup(props, { emit }) {
    const ruleForm = shallowRef<any>('')
    const data = reactive({
      formData: ref<any>({}),
      visible: props.modelValue
    })
    const rules = {
      carNo: [required, carNo]
    }
    const handleCloseDialog = () => {
      emit('update:modelValue', false)
    }

    // 提交表单
    function submitDialog() {
      ruleForm.value.validate((valid: boolean) => {
        if (valid) {
          const params: any = { ...data.formData }
          const methodFn = getAdd
          methodFn(params).then((res: any) => {
            ElMessage.success(res.msg)
            handleCloseDialog()
            emit('refreshList')
          })
        }
      })
    }

    return {
      ...toRefs(data),
      ruleForm,
      rules,
      submitDialog,
      handleCloseDialog
    }
  }
})
