/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-04-25 13:27:15
 * @LastEditors: yyx
 * @LastEditTime: 2022-09-21 13:47:52
 */
import service from '@/utils/request'

const API_RUL = {
  // 查询
  LIST: '/carApp/whiteList/page',
  ADD: '/carApp/whiteList/add',
  DELETE: '/carApp/whiteList/remove'
}

// 列表数据查询
export const getList = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.LIST,
    data: pramas
  })
// 新增白名单
export const getAdd = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.ADD,
    data: pramas
  })

// 删除白名单
export const getDelete = (pramas: any) =>
  service.request({
    method: 'post',
    url: API_RUL.DELETE,
    data: pramas
  })
