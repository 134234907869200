
import { btnName } from '@/utils/index'
import CTable from '@/components/c-table'
import ModifyDialog from './dialog.vue'
import { getTableConfig, getIndexConfig } from './config'
import CSearchList from '@/components/c-search-list'
import { defineComponent, reactive, toRefs } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getList, getDelete } from './api'

export default defineComponent({
  components: { CSearchList, CTable, ModifyDialog },

  setup() {
    // 表单组件变量
    const tableConfig = reactive({
      ...getTableConfig()
    })

    // 页面基础变量
    const data = reactive({
      ...getIndexConfig()
    })
    // 列表数据
    const getListFn = () => {
      const params = {
        currPage: tableConfig.pagination.currentPage,
        pageSize: tableConfig.pagination.pageSize
      }
      const obj = Object.assign(params, data.queryForm)
      tableConfig.loading = true
      getList(obj)
        .then(res => {
          tableConfig.data = res.data.list
          tableConfig.pagination.total = Number(res.data.total)
        })
        .finally(() => {
          tableConfig.loading = false
        })
    }
    const pageCurrentChange = (val: number) => {
      tableConfig.pagination.currentPage = val
      getListFn()
    }
    const pageSizeChange = (val: number) => {
      tableConfig.pagination.currentPage = 1
      tableConfig.pagination.pageSize = val
      getListFn()
    }

    // 执行搜索
    const handleSearch = (params: object) => {
      data.queryForm = params
      getListFn()
    }

    // 执行重置
    const handleReset = (params: object) => {
      data.queryForm = params
    }

    // 表单操作栏按钮
    const btnHandle = (type: string, row: any) => {
      console.log(row, 'row')
      switch (type) {
        case 'add': {
          data.title = '新建白名单'
          data.rowData = {}
          data.visible = true
          break
        }
        case 'del': {
          ElMessageBox.confirm('确认移除白名单吗？', '移除', {
            confirmButtonText: '确认移除',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            getDelete({ id: row.id }).then((res: any) => {
              ElMessage.success(res.msg)
              getListFn()
            })
          })
          break
        }
      }
    }

    // 关闭弹框
    const closeDialog = val => {
      data.visible = val
    }

    return {
      ...toRefs(data),
      handleSearch,
      handleReset,
      getListFn,
      closeDialog,
      tableConfig,
      btnHandle,
      pageCurrentChange,
      pageSizeChange,
      btnName
    }
  },
  mounted() {
    this.getListFn()
  }
})
